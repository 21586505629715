// Définissez une durée de vie pour la session (en millisecondes)
import axios from 'axios';
import router from '../router';
// const SESSION_DURATION = 30 * 60 * 1000; // 30 minutes

// Vérifiez la durée de vie de la session toutes les 5 minutes
setInterval(() => {
  const authData = JSON.parse(localStorage.getItem('authData'));

  if (authData) {
    const { expiresAt } = authData;

    if (new Date().getTime() > expiresAt) {
      // La session a expiré, supprimez les informations d'authentification et redirigez l'utilisateur vers la page de connexion
      localStorage.removeItem('authData');
      // localStorage.removeItem('authData');
      
      router.push('/login');
    }
  }
}, 5 * 60 ); // 5 minutes

// Utilisez des intercepteurs Axios pour ajouter les informations d'authentification à l'en-tête de la requête

axios.interceptors.request.use(
  config => {
    const authData = JSON.parse(localStorage.getItem('authData'));

    if (authData) {
      const { token } = authData;

      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
import Swal from 'sweetalert2';
import { getFirebaseBackend } from '../../authUtils.js'
import Api from '../../helpers/Api.service.js';
import router from '../../router/index.js';

export const state = {
    currentUser: sessionStorage.getItem('authUser'),
    user:"",
    user_token:""
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue
        saveState('auth.currentUser', newValue)
    },

    SET_USER_TOKEN(state, token) {
        state.user_token = token
    }
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser
    }
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        dispatch('validate')
    },

    // Logs in the current user.
    // logIn({ commit, dispatch, getters }, { email, password } = {}) {
    //     if (getters.loggedIn) return dispatch('validate')

    //     return getFirebaseBackend().loginUser(email, password).then((response) => {
    //         const user = response
    //         commit('SET_CURRENT_USER', user)
    //         return user
    //     });
    // },

    logInFromApi({ commit, dispatch, getters }, { email, password } = {}) {
        if (getters.loggedIn) return dispatch('validate')
        
        Api()
        .post("/login", {
            email: email,
            password: password
        })
        .then((res) => {

            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Vous êtes connecté ",
                text:res.data.user.name +" "+res.data.user.lastName,
                showConfirmButton: false,
                timer: 1600
              })
              .then(()=>{

                  this.tryingToLogIn = false;
                  this.isAuthError = false;
                  
                  let token1 = JSON.stringify(res.data.authorisation.token)
                  let user_id = JSON.stringify(res.data.user.id)
                  
                  token1 = token1.substring(1, token1.length - 1);
      
                  localStorage.setItem('user', JSON.stringify(res.data.user));
                  localStorage.setItem('user_token', token1);
                  localStorage.setItem('user_name', res.data.user.name);
                  localStorage.setItem('user_id',user_id)
      
                  const user = res.user
                  commit('SET_CURRENT_USER', user)
                  commit('SET_USER_TOKEN', token1)
      
                  router.push('/')
              })
            

            // this.$router.push(
            //     this.$route.query.redirectFrom || {
            //     name: "default",
            //     }
            // );
            // this.$router.push('/');
            // return user

        })
        .catch((err)=>{
            

            Swal.fire({
                position: "top-end",
                icon: "warning",
                title: "Login et/ou mot de passe incorrect",
                showConfirmButton: false,
                timer: 1600
              })
            
           
        })
    },

    // Logs out the current user.
    logOut({ commit }) {
        // eslint-disable-next-line no-unused-vars
        commit('SET_CURRENT_USER', null)
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line no-unused-vars
            getFirebaseBackend().logout().then((response) => {
                resolve(true);
            }).catch((error) => {
                reject(this._handleError(error));
            })
        });
    },

    // register the user
    register({ commit, dispatch, getters }, { username, email, password } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        return getFirebaseBackend().registerUser(username, email, password).then((response) => {
            const user = response
            commit('SET_CURRENT_USER', user)
            return user
        });
    },

    // register the user
    // eslint-disable-next-line no-unused-vars
    resetPassword({ commit, dispatch, getters }, { email } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        return getFirebaseBackend().forgetPassword(email).then((response) => {
            const message = response.data
            return message
        });
    },

    // Validates the current user's token and refreshes it
    // with new data from the API.
    // eslint-disable-next-line no-unused-vars
    validate({ commit, state }) {
        if (!state.currentUser) return Promise.resolve(null)
        const user = getFirebaseBackend().getAuthenticatedUser();
        commit('SET_CURRENT_USER', user)
        return user;
    },
}

// ===
// Private helpers
// ===

function saveState(key, state) {
    window.sessionStorage.setItem(key, JSON.stringify(state))
}

let ISLOCAL = 'prod' // local ou prod
let envoronnement = null

if (ISLOCAL == 'local') {
  envoronnement = {
   
    baseURL: `http://127.0.0.1:8000/api`,   // LOCAL

    adminEmail:'cheikhserignesalioutalla@gmail.com',
    
    reviewerEmail: 'cheikhserignesalioutalla@gmail.com',

    superAdminEmail:'cheikhserignesalioutalla@gmail.com',

    allSuperAdminer:[
      {'email':'cheikhserignesalioutalla@gmail.com', 'nom':'Cheikh Saliou TALLA'},
      {'email':'cheikhserignesalioutalla001@gmail.com', 'nom':'Cheikh Saliou 001'}
    ],
    
    allAdminer:[
      {'email':'cheikhserignesalioutalla@gmail.com', 'nom':'Cheikh Saliou TALLA'}
    ],

    allReviewers:[
      {'email':'cheikhserignesalioutalla@gmail.com', 'nom':'Cheikh Saliou TALLA'},
      {'email':'cheikhserignesalioutalla001@gmail.com', 'nom':'Cheikh Saliou TALLA'},
      {'email':'zalltalla46@gmail.com@gmail.com', 'nom':'ZALL TALLA'}
    ],
    
  }
}else if(ISLOCAL  == 'prod'){
  envoronnement = {
    // baseURL: `http://154.62.107.139:8080/api`,         // PROD FOR HOSTINGER
    // baseURL: `http://91.234.195.63/api`,               // PROD FOR LWS
    baseURL: `https://vps98041.serveur-vps.net/api`,   // PROD FOR LWS

    // adminEmail:'sambabery.kane@gmail.com',
    adminEmail:'aida.diop@wimsenegal.org',

    
    // reviewerEmail: 'cheikhserignesalioutalla@gmail.com',
    reviewerEmail: 'diarra.ndiaye@wimsenegal.org',
    allReviewers:[
      {'email':'gnagna.diene@wimsenegal.org', 'nom':'Gnagna DIÈNE'},
      {'email':'diarra.ndiaye@wimsenegal.org', 'nom':'Diarra NDIAYE'},
      {'email':'sambabery.kane@gmail.com','nom':'Samba B. KANE'}
    ],
   
    allAdminer:[
      {'email':'aida.diop@wimsenegal.org','nom':'Aida DIOP'},
      {'email':'sambabery.kane@gmail.com','nom':'Samba B. KANE'}
    ],

    superAdminEmail:'cheikhserignesalioutalla@gmail.com'
  }
}

export const apiConfig = envoronnement

/* eslint-disable no-const-assign */
import axios from "axios";
import router from "../router/index";
import Swal from "sweetalert2";

import { apiConfig } from "./config"

// import Vue from 'vue';

export default () => {
  let token = localStorage.getItem("user_token");

  const axiosConf = axios.create({
    baseURL: apiConfig.baseURL,
  
    headers: {
      "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      Accept: "*/*", "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT, DELETE",
      "X-Requested-With": "XMLHttpRequest",
    },
  });

  axiosConf.defaults.headers.post["Content-Type"] = "Application/json";

  let redirectUrl = null;
 
  axiosConf.interceptors.request.use(
    (axiosConf) => {
      return axiosConf;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
  axiosConf.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 403 ) {
        Swal.fire({
          title: "Accès a cette ressource est non autorisé ",
          text: "",
          icon: "info",
          confirmButtonColor: "#c15cfb",
          confirmButtonText: "OK",  
        })
        .then(()=>{
          redirectUrl = router.currentRoute.value.name;
  
          localStorage.setItem('oldUrl', redirectUrl)
  
          router.push({ name: "login" }); 

        }) 
      }
      else if(error.response && error.response.status === 401){
        
        
        Swal.fire({
          title: "Votre session a expiré!",
          text: "Veillez vous reconnecter",
          icon: "info",
          confirmButtonColor: "#c15cfb",
          confirmButtonText: "Regénérer la session",  
        })
        .then(()=>{
          redirectUrl = router.currentRoute.value.name;
  
          localStorage.setItem('oldUrl', redirectUrl)
  
          router.push({ name: "login" }); 
        }) 
    }
      else if(error.response.status === 405){
      
          Swal.fire({
            title: "Veillez verifier votre connexion internet!",
            text: "Ou bien, merci contacter l'administrateur",
            icon: "info",
            confirmButtonColor: "#c15cfb",
            confirmButtonText: "Ok",  
          })
          .then(()=>{
            redirectUrl = router.currentRoute.value.name;
    
            localStorage.setItem('oldUrl', redirectUrl)
    
            router.push({ name: "login" }); 
          }) 
      }
      return Promise.reject(error);
    }
  );

  //   Vue.prototype.$http = axiosConf;

  return axiosConf;
};

<template>
  <div></div>
</template>

<script>
import mitt from 'mitt';

export default {
  name: 'AutoLogout',
  mounted() {

    //retidiriger automatiquement vers la page login au bout de 20mn d'innactivités
    const idleTimeout = 20 * 60 * 1000; // 20 minutes en millisecondes
    let timeout;
    const eventEmitter = mitt();

    const resetTimer = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        this.$router.push('/login'); // Redirection vers la page de connexion
      }, idleTimeout);
    };

    const handleUserActivity = () => {
      eventEmitter.emit('userActivity');
      resetTimer();
    };

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);
    window.addEventListener('mousedown', handleUserActivity);
    window.addEventListener('touchstart', handleUserActivity);

    eventEmitter.on('userActivity', resetTimer);

    const beforeUnmountHandler = () => {
      clearTimeout(timeout);

      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      window.removeEventListener('mousedown', handleUserActivity);
      window.removeEventListener('touchstart', handleUserActivity);

      eventEmitter.off('userActivity', resetTimer);

      this.$destroy(); // Détruire le composant manuellement
    };

    this.$options.beforeUnmount = beforeUnmountHandler;
  },
};
</script>

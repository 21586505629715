<template>
 <router-view></router-view>
 <autoLogout></autoLogout>
</template>

<script>
import autoLogout from './components/system/autoLogout.vue';

export default {
  name: 'App',
  components: {
    autoLogout
  }
}
</script>
